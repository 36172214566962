
define(['$window', 'app'], ($window, app) => {
  const locatorSearch = () => {
    const component = {};

    const _config = {
      locator: '[data-js-element=locator]',
      locatorCard: '[data-js-element=locatorCard]',
      searchForm: '[data-js-element=locatorSearch_form]',
      searchInput: '[data-search]',
      geolocation: '[data-js-element=locatorSearch_geo]',
      searchDone: 'locatorSearch-done',
      searchShow: 'locator-show',
      autoCompleteId: 'store-locate-autocomplete',
      geoLocationOn: 'locatorSearch_myLocation-loading',
      geoActive: 'locatorSearch_myLocation-active'
    };

    const _init = (el) => {
      component.el = el;
      component.locator = document.querySelector(component.config.locator);
      component.formEl = el.querySelector(component.config.searchForm);
      component.inputEl = el.querySelector(component.config.searchInput);
      component.locationEl = el.querySelector(component.config.geolocation);
      component.formEl.addEventListener('submit', component.getSearchValue);
      component.locationEl.addEventListener('click', component.onGeoClick);
      app.subscribe('locatorCard/searchUrl', component.initSearchURL);

    };

    const _initSearchURL = () => {
      const searchQuery = component.getUrlParameter('query');
      component.getSearchURL(searchQuery);
      component.autoCompleteSearch = new $window.google.maps.places.Autocomplete(document.getElementById(component.config.autoCompleteId), {});
    };

    const _getSearchValue = (ev) => {
      ev.preventDefault();

      const url = document.URL;
      component.query = component.inputEl.value;

      component.getLocatorCards(component.query);
      $window.history.pushState({
        html: document.innerHTML,
        pageTitle: document.title,
      }, document.title, url.substr(0, url.indexOf('?')) + '?query=' + component.query);

      app.element.removeClass('locatorSearch-done', component.el);

      return false;
    };

    const _getUrlParameter = (name) => {
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i');
      const results = regex.exec($window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const _getSearchURL = (searchQuery) => {
      if (searchQuery.length > 0 && component.locator) {
        component.getLocatorCards(searchQuery);
        app.element.removeClass('locatorSearch-done', component.el);
      }
    };

    const _getLocatorCards = (query) => {
      app.element.removeClass(component.config.geoLocationOn, component.locationEl);
      app.ajax.get({
        url: `/search.locator?query=${query}`,
        success: component.onSuccess,
        error: component.onError
      });
    };

    const _onSuccess = (res) => {
      document.querySelector(component.config.locatorCard).outerHTML = res;
      component.addClasses();
      app.publish('_locator', null);
    };

    const _onError = () => {
      console.error('Sorry, something is wrong');
    };

    const _addClasses = () => {
      app.element.addClass(component.config.searchDone, component.el);
      app.element.addClass(component.config.searchShow, component.locator);
      app.element.addClass(component.config.geoActive, component.el);
    };

    const _onGeoClick = (ev) => {
      ev.preventDefault();

      if (!('geolocation' in navigator)) {
        console.error('No geolocation');

        return;
      }
      app.element.addClass(component.config.geoLocationOn, component.locationEl);
      app.element.removeClass(component.config.geoActive, component.el);
      navigator.geolocation.getCurrentPosition(component.getGeoLocationResult);
    };

    const _getGeoLocationResult = (pos) => {
      const query = `lon=${pos.coords.longitude}&lat=${pos.coords.latitude}`;

      app.publish('_locator/currentLocation', [pos.coords.latitude, pos.coords.longitude]);

      app.ajax.get({
        url: '/coordinate.locator?' + query,
        success: component.onSuccess,
        error: component.onError
      });
    };

    component.config = _config;
    component.init = _init;
    component.initSearchURL = _initSearchURL;
    component.getUrlParameter = _getUrlParameter;
    component.getSearchValue = _getSearchValue;
    component.getSearchURL = _getSearchURL;
    component.getLocatorCards = _getLocatorCards;
    component.onSuccess = _onSuccess;
    component.onError = _onError;
    component.onGeoClick = _onGeoClick;
    component.getGeoLocationResult = _getGeoLocationResult;
    component.addClasses = _addClasses;

    return component;
  };

  return locatorSearch;
});
